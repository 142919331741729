<template>
  <div class="calendar section section--body">
    <h3>Календар благоустрою</h3>
    <div class="holder">
      <iframe
        src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=2&amp;bgcolor=%23009688&amp;ctz=Europe%2FKiev&amp;src=OGI4dWswcWZhazRjZHAwb3NmZnRqdGoxa29AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=Nms5cHAxZ2duaGM2MmNqbjlubXZvNjQzZzRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=bzcwcTFoamMwYnIwb2NvaDhlY2hma3I5N2tAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=MjBtc283ZDF0amgxdmIxNjRiM3Zoa2pmaW9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=bmU4bzY1NGdjcGRkbm5oNzIyZmI2c3BiNDhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=cmVrZjhpMTNuampobG9tcHRkbWwwdmI5ZXNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23F4511E&amp;color=%23EF6C00&amp;color=%2333B679&amp;color=%237CB342&amp;color=%233F51B5&amp;color=%237986CB&amp;showTitle=0&amp;showNav=0&amp;showDate=0&amp;showPrint=0&amp;showTabs=0&amp;showTz=0"
        style="border-width: 0"
        width="800"
        height="600"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </div>
  </div>
</template>
<script>
  export default {};
</script>
<style lang="scss">
  .holder {
    display: flex;
    justify-content: center;
  }
</style>
